<template>
  <div class="container-fluid d-flex flex-column d-flex flex-fill">
    <div class="row search-header-wrapper gy-1 d-none d-lg-block" id="search-header-wrapper">
      <div class="col-sm-12">
        <span class="fw-bolder bg-dark text-wrap text-white">People Service </span>
        – Find passengers in need of assistance at the airport or in-flight.
        <span class="fw-bolder bg-dark text-wrap text-white"> Package Service</span>
        – Help someone by delivering a package internationally.
        <span class="fw-bolder bg-dark text-wrap text-white"> Product Service </span>– Shop
        internationally for somebody and deliver
      </div>
    </div>
    <div
      class="row search-block-input-wrapper gy-md-3 gy-lg-0 mt-sm-3 mt-lg-0"
      id="search-block-input-wrapper"
    >
      <div class="col-sm-12" v-if="commonErrors.length > 0">
        <ErrorFormField :msgArray="commonErrors" />
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3">
        <label class="label-require">Trepr Services</label>
        <!--        v-model="service"-->
        <select class="form-select" id="service" @change="onServiceChange">
          <option value="">Please select a service</option>
          <option value="people">People</option>
          <option value="package">Package</option>
          <option value="product">Product</option>
        </select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="rangeDatetimePicker" class="label-require">Date Time</label>
        <input type="text" class="form-control" id="rangeDatetimePicker" placeholder="Date Time" />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="originalLocation" class="label-require">Original Location</label>

        <div class="input-group">
          <span class="input-group-text">
            <div
              class="spinner-border spinner-border-sm text-secondary"
              role="status"
              v-if="$data.ajax.originalLocationLoad"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <i class="fas fa-plane-departure text-danger" v-else></i>
          </span>
          <input
            type="text"
            class="form-control"
            id="originalLocation"
            placeholder="Original Location"
            autocomplete="off"
          />
        </div>

        <input type="hidden" id="origin_type" />
        <input type="hidden" id="origin_code" />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label for="originalLocation" class="label-require">Destination Location</label>
        <div class="input-group">
          <span class="input-group-text">
            <LoadingIcons total="1" v-if="$data.ajax.destinationLocationLoad" />
            <i class="fas fa-plane-arrival text-danger" v-else></i>
          </span>
          <input
            type="text"
            class="form-control"
            id="destinationLocation"
            placeholder="Destination Location"
            autocomplete="off"
          />
        </div>

        <input type="hidden" id="destination_type" />
        <input type="hidden" id="destination_code" />
      </div>
    </div>
    <div class="row" id="optionPeople" v-if="$data.service == 'people'">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label>No of passengers (optional)</label>
        <select class="form-select" id="noofpassanger">
          <option value="">Select no of passengers</option>
          <option v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" :value="item" :key="{ item }">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-3">
        <label>Booking Status (Optional)</label>
        <select class="form-select" id="booking_status">
          <option selected="selected" value="">Select Booking Status</option>
          <option value="1">Booked</option>
          <option value="2">Unplanned</option>
        </select>
      </div>
    </div>
    <div class="row" id="optionPackage" v-if="$data.service == 'package'">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <label>Package weight (Optional)</label>
        <div class="row g-1">
          <div class="col-sm-9">
            <select class="form-select" id="package_weight">
              <option value="" selected>Select package weight</option>
              <option value="1-5">1 - 5</option>
              <option value="5-10">5 - 10</option>
              <option value="10-15">10 - 15</option>
              <option value="15-20">15 - 20</option>
              <option value="20-30">20 - 30</option>
              <option value="30-40">30 - 40</option>
              <option value="40-50">40 - 50</option>
              <option value="50+">50+</option>
            </select>
          </div>
          <div class="col-sm-3">
            <select class="form-select" id="package_weight_unit">
              <option value="kg">kg</option>
              <option value="lbs">lbs</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row" id="optionProduct" v-if="$data.service == 'product'">
      <div class="col-sm-12 col-md-6 col-lg-3">
        Product category (Optional)
        <select class="form-select" id="product_category">
          <option value="">Please select product category</option>
          <option v-for="item in $data.productCategories" :value="item.ID" :key="item.ID">
            {{ item.subcatname }}
          </option>
        </select>
      </div>
    </div>
    <div class="row search-block-confirm-wrapper mt-1 mb-lg-3" id="search-block-confirm-wrapper">
      <div class="col-6">
        <button
          type="submit"
          class="btn btn-seeker text-dark"
          id="btnSearch"
          @click="btnSearchClick"
          v-if="$data.ajax.canClickSearch"
        >
          Search
        </button>
        <LoadingIcons v-else />
      </div>
      <div class="col-6 d-flex justify-content-end d-lg-none">
        <div class="btn-group d-none" role="group" id="resultGroup">
          <button
            type="button"
            class="btn btn-danger"
            id="btnResultList"
            @click="btnResultListClick"
          >
            List
          </button>
          <button type="button" class="btn" id="btnResultMap" @click="btnResultMapCLick">
            Map
          </button>
        </div>
      </div>
    </div>

    <div class="row search-result flex-fill d-none g-0" id="searchResult">
      <div class="col-md-12 col-lg-4 col-xl-3 pr-lg-2" id="searchResultList">
        <ul
          class="list-group list-group-flush"
          v-if="$data.resultArray.length > 0"
          id="ulSearchResultList"
          v-on:scroll="handleScroll"
        >
          <ResultItem
            v-for="(item, index) in $data.resultArray"
            :item="{ item }"
            :key="{ index }"
          />
          <li class="list-group-item text-center" v-if="$data.ajax.isLoadedShowMore">
            <!--          <li class="list-group-item text-center">-->
            <LoadingIcons total="7" />
          </li>
        </ul>
        <div v-else>
          <h3>No result</h3>
        </div>
      </div>
      <div class="col-md-12 col-lg-8 col-xl-9 d-lg-block d-flex" id="searchResultMap" style="">
        <div class="map-frame flex-fill" v-if="$data.resultArray.length > 0">
          <!--                    <MyGoogleMap />-->
          <MyGoogleMap
            :center="{
              lat: $data.initGmap.center.lat,
              lng: $data.initGmap.center.long,
            }"
          >
          </MyGoogleMap>
        </div>
      </div>
    </div>
  </div>

  <!--    <div>-->
  <!--        <h1>test-->
  <!--            :disableUI="false"-->
  <!--            :zoom="12"-->
  <!--            mapType="roadmap"-->
  <!--        </h1>-->
  <!--        <MyGoogleMap-->
  <!--                     :center="{ lat: 38.8977859, long: -77.0057621 }"> </MyGoogleMap>-->
  <!--    </div>-->
</template>

<script type="application/javascript">
/* eslint-disable */
import ErrorFormField from '../../common/error/ErrorFormField';
import LoadingIcons from '../../common/LoadingIcons';
import ResultItem from '../ResultItem';
// import ErrorFormField from "../../common/error/ErrorFormField";
import jQuery from 'jquery';
import MyGoogleMap from '../MyGoogleMap';
import SearchTravellerHandler from './SearchTraveller.js';
import MyAutocomplete from '../MyAutocomplete';

import bootstrap from 'bootstrap';
window.moment = require('moment');
let autocomplete = require('autocompleter');

window.daterangepicker = require('daterangepicker');
const $ = jQuery.noConflict();
// window.$ = $;
//step 2 init date range

let start = moment().subtract(60, 'days'); //eslint-disable
// let start = moment();//eslint-disable
// let end = moment().add(7, 'days');//eslint-disable
let end = moment().add(67, 'days'); //eslint-disable
//eslint-disable-nex-line
var // pagePeople = 1,
  // pagePackage = 1,
  // pageProduct = 1,
  // canLoadPeople = true,
  // canLoadProduct = true,
  // canLoadPackage = true,
  isClickedSearch = false;
// marker,
// maplarge,
// originalLatitude,
// originalLongitude;
let apiUrl = process.env.VUE_APP_API_URL;

function cb(start, end) {
  $('#rangeDatetimePicker span').html(
    start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
  );
  // document.getElementById("rangeDatetimePicker").
  // console.log("cb test")
}
//-------------AUto complete--------------------------

var classNameAuto = 'bg-dark text-white list-group list-group-flush';
//-------------END AUto complete--------------------------

function btnSearchClickHandler(e) {
  this.$data.ajax.canClickSearch = false;
  let service = document.getElementById('service').value;
  let tmpDate1 = document.getElementById('rangeDatetimePicker').value;
  let destinationLocation = document.getElementById('destinationLocation').value;
  let originalLocation = document.getElementById('originalLocation').value;
  let origin_type = document.getElementById('origin_type').value;
  let destination_type = document.getElementById('destination_type').value;
  this.commonErrors = [];
  if (service.length == 0) {
    this.commonErrors.push('Please choose a service');
  }
  if (tmpDate1.length == 0) {
    this.commonErrors.push('Please choose a date range');
  }

  if (originalLocation.length == 0) {
    this.commonErrors.push('Please fill original location field');
  } else {
    if (origin_type.length == 0) {
      this.commonErrors.push('Please choose original location in the showing list');
    }
  }
  if (destinationLocation.length == 0) {
    this.commonErrors.push('Please fill destination location field');
  } else {
    if (destination_type.length == 0) {
      this.commonErrors.push('Please choose destination location in the showing list');
    }
  }

  if (this.commonErrors.length > 0) {
    this.$data.ajax.canClickSearch = true;
    return false;
  }
  isClickedSearch = true;
  let obj = {
    origin_type: document.getElementById('origin_type').value,
    origin_code: document.getElementById('origin_code').value,
    destination_type: document.getElementById('destination_type').value,
    destination_code: document.getElementById('destination_code').value,
  };
  // obj.item_per_page = 1; //test
  let dateArray = tmpDate1.split(' - ');
  obj.start_date = dateArray[0].trim();
  obj.end_date = dateArray[1].trim();
  if (service == 'people') {
    obj.noofpassanger = document.getElementById('noofpassanger').value;
    obj.booking_status = document.getElementById('booking_status').value;
    this.$data.common.pagePeople = 1;
    this.$data.ajax.canLoadPeople = true;
    obj.page = this.$data.common.pagePeople;

    SearchTravellerHandler.getListServicePeople(obj, this);
  } else if (service == 'package') {
    obj.package_weight = document.getElementById('package_weight').value;
    obj.package_weight_unit = document.getElementById('package_weight_unit').value;
    this.$data.common.pagePackage = 1;
    this.$data.ajax.canLoadPackage = true;
    obj.page = this.$data.common.pagePackage;
    // console.log("people service", obj);
    SearchTravellerHandler.getListServicePackage(obj, this);
  } else {
    //product
    this.$data.common.pageProduct = 1;
    this.$data.ajax.canLoadProduct = true;
    obj.page = this.$data.common.pageProduct;
    // obj.product_category = $("#product_category").val();
    obj.product_category = document.getElementById('product_category').value;
    // console.log("project service homepage ", obj);
    SearchTravellerHandler.getListServiceProduct(obj, this);
  }

  if (window.screen.width <= 991) {
    //" only hide search block after click with mobile, tablet in veertical mode
    $('#search-block-input-wrapper').toggleClass('d-none');
    $('#btnSearch').toggleClass('d-none');
    $('#openFilter').toggleClass('text-primary');
    if ($('#searchResultList').hasClass('d-none')) {
      $('#searchResultList').removeClass('d-none');
    }
    if (!$('#searchResultMap').hasClass('d-none')) {
      $('#searchResultMap').addClass('d-none');
    }
  }

  if ($('#resultGroup').hasClass('d-none')) {
    $('#resultGroup').removeClass('d-none');
  }

  if ($('#searchResult').hasClass('d-none')) {
    $('#searchResult').removeClass('d-none');
  }
}

function handleScrollHandler() {
  if (!this.$data.ajax.canLoadPeople) return;
  // console.log("search_results scroll " + $("#ulSearchResultList").scrollTop()  + " - " + $("#ulSearchResultList").innerHeight() + " - " + $("#ulSearchResultList")[0].scrollHeight);
  if (!isClickedSearch) return;
  // let tmp = $("#ulSearchResultList").scrollTop() + $("#ulSearchResultList").innerHeight() ;
  // console.log("tmp : " + tmp)
  var self = this;
  if (
    $('#ulSearchResultList').scrollTop() + $('#ulSearchResultList').innerHeight() >=
    $('#ulSearchResultList')[0].scrollHeight
  ) {
    let service = document.getElementById('service').value;
    let tmpDate1 = document.getElementById('rangeDatetimePicker').value;
    console.log(' search_results end reached');
    let obj = {
      origin_type: document.getElementById('origin_type').value,
      origin_code: document.getElementById('origin_code').value,
      destination_type: document.getElementById('destination_type').value,
      destination_code: document.getElementById('destination_code').value,
    };
    obj.item_per_page = 1; //test

    let dateArray = tmpDate1.split(' - ');
    obj.start_date = dateArray[0].trim();
    obj.end_date = dateArray[1].trim();
    self.$data.ajax.isLoadedShowMore = true;
    if (service == 'people') {
      obj.noofpassanger = document.getElementById('noofpassanger').value;
      obj.booking_status = document.getElementById('booking_status').value;
      self.$data.ajax.canLoadPeople = true;

      obj.page = this.$data.common.pagePeople;
      SearchTravellerHandler.showMoreServicePeople(obj, this);
    } else if (service == 'package') {
      // tmpObj.package_weight = $("#package_weight").val();
      // tmpObj.package_weight_unit = $("#package_weight_unit").val();
      obj.package_weight = document.getElementById('package_weight').value;
      obj.package_weight_unit = document.getElementById('package_weight_unit').value;
      self.$data.ajax.canLoadPackage = true;
      obj.page = this.$data.common.pagePackage;
      SearchTravellerHandler.showMoreServicePackage(obj, this);
    } else {
      //product
      obj.product_category = document.getElementById('product_category').value;
      self.$data.ajax.canLoadProduct = true;
      obj.page = this.$data.common.pageProduct;
      SearchTravellerHandler.showMoreServicePackage(obj, this);
    }
  }
}
//---------------------------------btn search handle
//-----------------------------------------end btn search handle
export default {
  name: 'SearchTraveller',
  layout: {
    name: 'traveller',
  },
  components: { ResultItem, ErrorFormField, MyGoogleMap, LoadingIcons },
  data: function () {
    return {
      service: '',
      commonErrors: [],
      resultArray: [],
      productCategories: [],
      initGmap: {
        // streetViewControl: true,
        // scaleControl: true,
        center: { lat: null, long: null },
        originalLatitude: undefined,
        originalLongitude: undefined,
        // zoom: 2,
      },
      ajax: {
        canClickSearch: true,
        canLoadPeople: true,
        canLoadPackage: true,
        originalLocationLoad: false,
        destinationLocationLoad: false,
        isLoadedShowMore: false,
      },
      common: {
        pagePeople: 1,
        pagePackage: 1,
      },
    };
  },
  computed: {
    showLoadingResultIcon: function () {
      // let ajax = this.$data.ajax
      if (this.$data.ajax.canLoadPeople == true) {
        return true;
      }
      return false;
    },
  },
  methods: {
    btnSearchClick: btnSearchClickHandler,
    handleScroll: handleScrollHandler,
    onServiceChange: function (e) {
      this.$data.service = e.target.value;
      this.$data.resultArray = [];
    },
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    let self = this;
    let apiUrl = process.env.VUE_APP_API_URL;
    let token = self.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    fetch(apiUrl + 'common/get-product-category', {
      method: 'GET',
      headers: headersObject,
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data for product category' + resData.message);
          return;
        }
        this.$data.productCategories = resData.productCategories;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    delete window.moment;
    delete window.daterangepicker;
  },
  mounted() {
    document.title = 'Traveller Browser Seeker | Trepr';
    var self = this;
    $.ajaxSetup({
      headers: {
        Authorization: 'Bearer ' + this.$store.state.token,
      },
      beforeSend: function () {
        $('#general-ajax-load').fadeIn();
      },
      complete: function () {
        $('#general-ajax-load').fadeOut();
      },
      success: function () {
        $('#general-ajax-load').fadeOut();
      },
    });
    if (window.moment == undefined) {
      window.moment = require('moment');
    }
    $('#rangeDatetimePicker').daterangepicker(
      {
        timePicker: true,
        timePicker24Hour: true,
        // timePickerSeconds: true,
        startDate: start,
        endDate: end,
        autoApply: true,
        ranges: {
          '1 Days': [moment(), moment().add(1, 'days')],
          '3 Days': [moment(), moment().add(3, 'days')],
          '1 Week': [moment(), moment().add(7, 'days')],
          '2 Weeks': [moment(), moment().add(14, 'days')],
          '3 Weeks': [moment(), moment().add(21, 'days')],
          '1 Months': [moment(), moment().add(1, 'months')],
          '2 Months': [moment(), moment().add(2, 'months')],
          '3 Months': [moment(), moment().add(3, 'months')],
          '6 Months': [moment(), moment().add(6, 'months')],
        },
        locale: {
          format: 'YYYY-MM-DD HH:mm',
        },
      },
      cb
    );

    cb(start, end);
    //------------------search button click
    $('#btnResultList').click(function () {
      if ($(window).width() <= 991) {
        //" only hide search block after click with mobile, tablet in vertical mode
        if ($('#searchResultList').hasClass('d-none')) {
          $('#searchResultList').removeClass('d-none');
        }
        if (!$('#searchResultMap').hasClass('d-none')) {
          $('#searchResultMap').addClass('d-none');
        }
        $('#btnResultList').toggleClass('btn-danger');
        $('#btnResultMap').toggleClass('btn-danger');
      }
    });
    $('#btnResultMap').click(function () {
      if ($(window).width() <= 991) {
        //" only hide search block after click with mobile, tablet in vertical mode
        if ($('#searchResultMap').hasClass('d-none')) {
          $('#searchResultMap').removeClass('d-none');
        }
        if (!$('#searchResultList').hasClass('d-none')) {
          $('#searchResultList').addClass('d-none');
        }
        $('#btnResultList').toggleClass('btn-danger');
        $('#btnResultMap').toggleClass('btn-danger');
      }
    });
    //--------------end search button click

    var inputOriginalLocation = document.getElementById('originalLocation');
    var inputDestinationLocation = document.getElementById('destinationLocation');
    // MyAutocomplete.initData(this);
    // this.$data.ajax.originalLocationLoad
    // let objCommonAutocomplete =
    autocomplete({
      input: inputOriginalLocation,
      minLength: 2,
      disableAutoSelect: true,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'original'),
      className: classNameAuto,
      render: MyAutocomplete.renderAuto,
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'original'),
      onSelect: function (item) {
        console.log('select data autocomple orignal');
        console.log(item.coordinates);
        let tmpCoordinates = item.coordinates;
        self.$data.initGmap.center.lat = tmpCoordinates.lat;
        self.$data.initGmap.center.long = tmpCoordinates.lon;
        inputOriginalLocation.value = '(' + item.code + ') ' + item.name;
        document.getElementById('origin_type').value = item.type;
        document.getElementById('origin_code').value = item.code;
      },
    });
    // let objCommonAutocomplete =
    autocomplete({
      input: inputDestinationLocation,
      minLength: 2,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'destination'),
      className: classNameAuto,
      render: MyAutocomplete.renderAuto,
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'destination'),
      onSelect: function (item) {
        inputDestinationLocation.value = '(' + item.code + ') ' + item.name;
        document.getElementById('destination_type').value = item.type;
        document.getElementById('destination_code').value = item.code;
      },
    });

    // $(window).scroll(function(){
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/daterangepicker/daterangepicker.css';
@import '../../../assets/scss/new/page/search';
/*@import "../../../../node_modules/bootstrap/scss/bootstrap";*/
</style>
